<!--
 * @Description: 产品详情
 * @Author: zhang zhen
 * @Date: 2023-02-07 15:56:03
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-13 15:20:29
 * @FilePath: /page-sass/src/views/productInfo/index.vue
-->
<template>
  <div class="productInfo">
    <h3 class="productInfo-title">LED球泡灯牛皮纸盒</h3>
    <a-row :gutter="80" class="productInfo-mainInfo">
      <a-col :span="10" class="banner-box">
        <!-- <a-carousel class="product-big" arrows :dots="false">
          <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div><h3>1</h3></div>
          <div><h3>2</h3></div>
          <div><h3>3</h3></div>
          <div><h3>4</h3></div>
        </a-carousel> -->
        <div class="cover">
          <a-carousel class="product-big" arrows dots-class="slick-dots slick-thumb">
            <a slot="customPaging" slot-scope="props">
              <img :src="getImgUrl(props.i)" />
            </a>
            <div v-for="item in productList" :key="item">
              <img :src="item" />
            </div>
          </a-carousel>
        </div>
      </a-col>
      <a-col :span="10" class="center-box" style="padding-left: 60px">
        <div class="product-price-content">
          <span class="title">定制价格</span>
          <span class="price">¥58.00～78.00</span>
          <span class="miniTitle">100个起订</span>
        </div>
        <div class="product-content">
          <div class="product-content-item">
            <span class="label">服务</span>
            <div class="info">不支持七天无理由退货</div>
          </div>
          <div class="product-content-item">
            <span class="label">支付</span>
            <div class="info">支付宝 微信转账 银联支付</div>
          </div>
          <div class="product-content-item">
            <span class="label">物流</span>
            <div class="info">
              山西省 运城市 至
              <a-select defaultValue="1" size="small" style="width: 100px;margin-left: 10px">
                <a-select-option key="1" value="1">南京市</a-select-option>
              </a-select>
            </div>
          </div>
          <div class="product-content-item">
            <span class="label">支持</span>
            <div class="info last-item">
              选款定制 100个起订 贴牌 印图印字 包装定制 改款定制 订货生产 按需定制 来样加工来图加工 包装定制
            </div>
          </div>
          <div class="product-content-item">
            <span class="label">出货</span>
            <div class="info">预计7天</div>
          </div>
        </div>
        <!-- 规格模块 -->
        <div class="product-style">
          <div class="product-style-nav">
            <div class="product-style-nav-item" :class="PriceIndex == 0 && 'active'" @click="handleSwitchIndex('0')">
              拿样
            </div>
            <div class="product-style-nav-item" :class="PriceIndex == 1 && 'active'" @click="handleSwitchIndex('1')">
              选定款式
            </div>
            <div class="product-style-nav-item" :class="PriceIndex == 2 && 'active'" @click="handleSwitchIndex('2')">
              按需定制
            </div>
            <div class="product-style-nav-item" :class="PriceIndex == 3 && 'active'" @click="handleSwitchIndex('3')">
              打样
            </div>
          </div>
          <!-- 拿样 -->
          <div class="product-style-samples" v-if="PriceIndex == 0 || PriceIndex == 1">
            <span class="tagName">颜色</span>
            <div class="product-style-samples-content">
              <div class="product-style-samples-content-item">
                <div class="left-box">
                  <img src="~@/assets/100x100.png" alt="" />
                  <div class="samples-title">一层纸盒</div>
                </div>
                <span class="price">200.00元</span>
                <div class="num">
                  <a-button icon="minus" size="small"></a-button>
                  <a-input-number :min="1" defaultValue="1" size="small" />
                  <a-button icon="plus" size="small"></a-button>
                </div>
              </div>

              <div class="product-style-samples-content-item">
                <div class="left-box">
                  <img src="~@/assets/100x100.png" alt="" />
                  <div class="samples-title">二层纸盒</div>
                </div>
                <span class="price">200.00元</span>
                <div class="num">
                  <a-button icon="minus" size="small"></a-button>
                  <a-input-number :min="1" defaultValue="1" size="small" />
                  <a-button icon="plus" size="small"></a-button>
                </div>
              </div>

              <div class="product-style-samples-content-item">
                <div class="left-box">
                  <img src="~@/assets/100x100.png" alt="" />
                  <div class="samples-title">三层纸盒</div>
                </div>
                <span class="price">200.00元</span>
                <div class="num">
                  <a-button icon="minus" size="small"></a-button>
                  <a-input-number :min="1" defaultValue="1" size="small" />
                  <a-button icon="plus" size="small"></a-button>
                </div>
              </div>
            </div>
          </div>

          <!-- 按需定制 -->
          <div class="product-customized" v-else-if="PriceIndex == 2">
            <div class="product-customized-item">
              <span class="label">单价</span>
              <div class="number-picker">
                <a-button icon="minus"></a-button>
                <a-input-number :min="20" style="width: 140px;" defaultValue="10" />
                <span class="unit">元/个</span>
                <a-button icon="plus"></a-button>
              </div>
            </div>
            <div class="product-customized-item">
              <span class="label">数量</span>
              <div class="number-picker">
                <a-button icon="minus"></a-button>
                <a-input-number :min="20" style="width: 140px;" defaultValue="200" />
                <span class="unit">个</span>
                <a-button icon="plus"></a-button>
              </div>
            </div>
          </div>
          <!-- 打样 -->
          <div class="product-customized" v-else>
            <div class="product-customized-item">
              <span class="label">单价</span>
              <div class="number-picker">
                <a-button icon="minus"></a-button>
                <a-input-number :min="20" style="width: 140px;" defaultValue="200" :disabled="true" />
                <span class="unit">元/个</span>
                <a-button icon="plus"></a-button>
              </div>
            </div>
            <div class="product-customized-item">
              <span class="label">数量</span>
              <div class="number-picker">
                <a-button icon="minus"></a-button>
                <a-input-number :min="20" style="width: 140px;" defaultValue="1" />
                <span class="unit">个</span>
                <a-button icon="plus"></a-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="product-determine">
          <span class="memo">请与商家协商一致，按约定填写，或下单后由商家修改</span>
          <div class="payPrice">
            <span class="price">¥2000.00</span>
            <span class="num">共<b>0</b>个</span>
            <span class="day">支付成功后预计10~15天出货</span>
          </div>
          <a-button type="primary" class="payBtn">{{
            PriceIndex == '0' || PriceIndex == '1' ? '确认' : PriceIndex == '2' ? '立即定制' : '立即打样'
          }}</a-button>
        </div>
      </a-col>
      <a-col :span="4" class="tag-box" style="padding-left: 80px">
        <div class="tag-content">
          <h2 class="tag-content-title">某某制造厂</h2>
          <div class="tag-content-subTitle">标签</div>
          <div class="tag-content-list">
            <div class="tag-content-list-item">来样加工</div>
            <div class="tag-content-list-item">按需定制</div>
            <div class="tag-content-list-item">包装定制</div>
            <div class="tag-content-list-item">来图加工</div>
            <div class="tag-content-list-item">生产制造</div>
          </div>
          <div class="entryBtn">进入工厂</div>
        </div>
      </a-col>
    </a-row>

    <div class="product-details">
      <a-tabs default-active-key="1" @change="handleChangeInfoIndex">
        <a-tab-pane key="1" tab="详细信息">
          <div class="contentInfo">
            <img
              src="https://img30.360buyimg.com/sku/jfs/t1/45130/14/16932/350837/5ddf3fb0Ea4eec6c8/227608450cbffc28.jpg.avif"
              alt=""
            />
            <img
              src="https://img30.360buyimg.com/sku/jfs/t1/62487/35/16473/145871/5ddf3fb0Ea77e32ff/8649d2d716e7c745.jpg.avif"
              alt=""
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="客户评价" force-render>
          <a-empty :image="simpleImage" description="当前暂无评价记录"/>
        </a-tab-pane>
        <a-tab-pane key="3" tab="交易明细">
          <div class="pay-flows">
            <a-tag color="#266fe8" class="pay-flows-tag">1、选择商品</a-tag>
            <a-tag color="#266fe8" class="pay-flows-tag">2、确认订单信息</a-tag>
            <a-tag color="#266fe8" class="pay-flows-tag">3、签约付款</a-tag>
            <a-tag color="#266fe8" class="pay-flows-tag">4、收货验货</a-tag>
            <a-tag color="#266fe8" class="pay-flows-tag">5、交易成功</a-tag>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
export default {
  name: 'productInfo',
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      productList: [
        'https://img10.360buyimg.com/n1/jfs/t1/223635/23/10136/337691/627b6159E3c9ca37c/eea88c957b12b689.jpg',
        'https://img10.360buyimg.com/n1/jfs/t1/128541/37/27438/82480/625630e0E1002286d/9d1089273777fc27.jpg',
        'https://img10.360buyimg.com/n1/jfs/t1/193306/30/23442/105177/625630e1E0fbf73e2/c4095f8eb1bac379.jpg',
        'https://img10.360buyimg.com/n1/jfs/t1/126378/3/20483/110765/625630e1Ee909d969/5220dd599a109bbe.jpg'
      ],
      PriceIndex: 0
    }
  },
  methods: {
    getImgUrl(i) {
      return this.productList[i]
    },
    handleSwitchIndex(index) {
      this.PriceIndex = index
    },
    /* 详情信息切换 */
    handleChangeInfoIndex(e) {}
  }
}
</script>

<style lang="less" scoped>
.productInfo {
  width: calc(100vw - 140px);
  padding: 30px 15px 20px 25px;
  background: rgba(242, 242, 242, 1);
  border-radius: 6px;
  margin: 60px auto;
  overflow: hidden;
  &-mainInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &-title {
    color: #000;
    font-size: 15px;
    margin-bottom: 40px;
  }
  //   .banner-box {
  //     padding-bottom: 70px;
  //   }
  .cover {
    background: #d7d7d7;
    padding-bottom: 90px;
  }
  .product-big {
    .slick-dots {
      height: auto;
    }
    .slick-slide img {
      //   border: 5px solid #fff;
      display: block;
      margin: auto;
      max-width: 80%;
    }
    ::v-deep .slick-thumb {
      bottom: -20px;
    }
    ::v-deep .slick-thumb li {
      //   width: 60px;
      height: 60px;
    }
    ::v-deep .slick-thumb li img {
      width: 100%;
      height: 100%;
      filter: grayscale(100%);
    }
    ::v-deep .slick-thumb li.slick-active img {
      filter: grayscale(0%);
    }
    ::v-deep .slick-slide {
      height: 305px;
      background: #c9c9c9;
    }
  }

  .product-price-content {
    width: 100%;
    padding: 20px 15px 20px;
    background: #fff;
    margin-bottom: 15px;
    .flexLayout(@justifyContent: flex-start);
    position: relative;
    &::after {
      display: inline-block;
      content: '本产品采购属于商业贸易行为';
      font-size: 12px;
      color: #797979;
      position: absolute;
      right: 0;
      top: -20px;
    }
    .title {
      color: #797979;
      margin-right: 30px;
    }
    .price {
      color: #266fe8;
      font-size: 24px;
      font-weight: 600;
      margin-right: 15px;
    }
    .miniTitle {
      color: #797979;
      font-size: 12px;
    }
  }
  .product-content {
    width: 100%;
    background: #fff;
    padding: 10px;
    margin-bottom: 15px;
    &-item {
      .flexLayout(@justifyContent: flex-start);
      align-items: flex-start;
      font-size: 12px;
      color: #797979;
      margin-bottom: 8px;
      line-height: 24px;
      .label {
        margin-right: 30px;
      }
      .info {
        flex: 1 0 0;
        min-width: 0;
      }
      .last-item {
        max-width: 480px;
      }
    }
  }
  .product-style {
    width: 100%;
    background: #fff;
    padding: 20px;
    &-nav {
      .flexLayout(@justifyContent: space-around);
      margin-bottom: 15px;
      &-item {
        cursor: pointer;
        color: #787878;
        &.active {
          color: #0099ff;
        }
      }
    }
    &-samples {
      position: relative;
      padding: 15px 15px 15px 80px;
      .tagName {
        color: #787878;
        position: absolute;
        left: 0;
        top: 25px;
      }
      &-content {
        width: 100%;
        &-item {
          .flexLayout();
          flex-wrap: wrap;
          padding: 8px 0 0;
          border-bottom: 1px dashed #d9d9d9;
          &:last-of-type {
            border: none;
          }
        }
        .left-box {
          .flexLayout(@justifyContent: flex-start);
          margin-bottom: 15px;
        }
        .samples-title {
          width: 120px;
          color: #000;
          font-size: 18px;
          font-weight: 600;
          .text-emphasis();
        }
        img {
          width: 40px;
          margin-right: 15px;
        }
        .price {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .num {
          border: 1px solid #d9d9d9;
          .flexLayout(@justifyContent: flex-start);
          margin-bottom: 15px;
          .ant-btn {
            border: none;
          }
          .ant-input-number {
            border: none;
          }

          ::v-deep .ant-input-number-input {
            text-align: center;
          }
        }
      }
    }
  }

  .product-customized {
    width: 100%;
    padding: 40px 0 40px 20px;
    &-item {
      .flexLayout(@justifyContent: flex-start);
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
      .label {
        color: #787878;
        display: inline-block;
        width: 50px;
        font-size: 12px;
        margin-right: 8px;
      }
      .number-picker {
        background: #f8f8f8;
        border-radius: 4px;
        .ant-btn {
          border: none;
          background: transparent;
        }
        .ant-input-number {
          border: none;
          background: transparent;
        }

        ::v-deep .ant-input-number-input {
          text-align: center;
        }
        .unit {
          color: #787878;
          margin: 0 8px;
          display: inline-block;
          width: 33px;
          text-align: center;
        }
      }
    }
  }
  .product-determine {
    margin-top: 15px;
    width: 100%;
    background: #fff;
    padding: 15px;
    .memo {
      color: #797979;
      font-size: 12px;
    }
    .payPrice {
      margin: 15px 0;
      .flexLayout(@justifyContent: flex-start);
      font-size: 12px;
      color: #797979;
      .price {
        color: #266fe8;
        font-size: 18px;
        font-weight: 500;
        margin-right: 10px;
      }
      .num b {
        color: #266fe8;
      }
      .day {
        margin-left: 10px;
      }
    }
    .payBtn {
      width: 150px;
      height: 40px;
      font-weight: 600;
    }
  }
  .tag-content {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 8px 15px 8px;
    text-align: center;
    &-title {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0;
    }
    &-subTitle {
      color: #fff;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #ff3333;
      border-radius: 2px;
      margin: 4px auto;
      font-size: 12px;
      font-weight: bold;
    }
    &-list {
      margin: 30px 0 60px;
      .flexLayout();
      flex-wrap: wrap;
      &-item {
        width: 70px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #0099ff;
        color: #0099ff;
        margin-bottom: 10px;
      }
    }
    .entryBtn {
      width: 100%;
      height: 40px;
      line-height: 38px;
      color: #53a6fa;
      background: rgba(228, 242, 254, 1);
      border: 1px solid #8fc6fc;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: #53a6fa;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .product-details {
    width: 100%;
    padding: 0 30px 30px;
    background: #fff;
    border-radius: 2px;
    .contentInfo {
      width: 100%;
      padding: 0 80px;
      img {
        width: auto;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .pay-flows {
    width: 100%;
    .flexLayout(@justifyContent: center);
    flex-wrap: wrap;
    &-tag {
        width: 109px;
        height: 27px;
        line-height: 27px;
        font-weight: bold;
        font-size: 13px;
        text-align: center;
        position: relative;
        overflow: visible;
        margin-right: 50px;
        &::after {
            display: block;
            content: '';
            width: 30px;
            height: 30px;
            background: url(~@/assets/rightArrow.png);
            background-size: 100% 100%;
            position: absolute;
            right: -40px;
            top: -3px;
        }
        &:last-of-type {
            &::after {
                display: none;
            }
        }
    }
  }
}

@media (max-width: 1832px) {
  .productInfo-mainInfo {
    .center-box {
      padding-left: 20px!important;
      padding-right: 20px!important;
      .product-style-samples {
        padding-left: calc((80 / 1920) * 100vw);
      }
    }
    .tag-box {
      padding-left: 30px!important;
    }
  }
}
</style>
